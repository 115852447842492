import { ComponentSlider } from '@emico-react/component-slider'
import { Picture } from '@emico-react/image'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'

import { Maybe } from '@emico/graphql-schema-types'
import { minWidth, maxWidth } from '@emico/styles'
import { Container, H3 } from '@emico/ui'

import { CraftPageBlocksFragmentCraftWizardSliderEntry } from '../lib/craftFragments.generated'
import Unwrap from '../lib/UnWrap'
import { PrevButton, StyledNextButton } from '../overrides/ComponentSlider'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import HtmlContent from './HtmlContent'
import SectionTitle from './SectionTitle'

const Wizard = styled.div`
  padding: ${theme.spacing['3xl']} 0;
  background-color: ${theme.colors.backgroundLight};
`

const StyledContainer = styled(Container)`
  @media ${minWidth('md')} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`

const Header = styled.header`
  margin-bottom: ${theme.spacing['3xl']};
  text-align: center;

  p {
    margin-bottom: 0;
  }

  @media ${minWidth('md')} {
    grid-column: 2 / span 10;
  }

  @media ${minWidth('lg')} {
    grid-column: 3 / span 8;
  }
`

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: ${theme.spacing.sm};

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledComponentSliderMobileAndTablet = styled(ComponentSlider)`
  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledComponentSliderDesktop = styled(ComponentSlider)`
  ${() => css`
    ${PrevButton}, ${StyledNextButton} {
      height: 100%;
      top: 100%;
      width: 7vw;
      display: flex;
      align-items: center;
    }

    ${PrevButton} {
      left: 0;
      right: auto;
      justify-content: flex-end;
    }
  `}

  @media ${maxWidth('md')} {
    display: none;
  }
`

const Step = styled('article', {
  shouldForwardProp: (prop) =>
    !['currentSlide', 'slideIndex'].includes(prop.toString()),
})<{ currentSlide: number; slideIndex: number }>`
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ currentSlide, slideIndex }) =>
    currentSlide !== slideIndex && 0.5};
`

const StepHeader = styled.header`
  margin-bottom: ${theme.spacing.md};
`

const StepTitle = styled(H3)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes.xl};
    text-transform: uppercase;
  }
`

const Figure = styled.figure`
  margin-bottom: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    margin-bottom: ${theme.spacing.xl};
  }

  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing['2xl']};
  }
`

const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
  border-radius: ${theme.borderRadius.base};
`

const StepContent = styled.div`
  @media ${minWidth('lg')} {
    display: grid;
    grid-template-columns: 60%;
  }
`

type StepType = Unwrap<
  Maybe<
    Exclude<CraftPageBlocksFragmentCraftWizardSliderEntry['wizardSteps'], null>
  >
>

interface StepProps {
  step: StepType
  stepCount: number
  slideIndex: number
  currentSlide: number
}

const SlideStep = ({
  step,
  stepCount,
  slideIndex,
  currentSlide,
}: StepProps) => {
  const image = step?.image?.[0]

  if (!step || !image) {
    return null
  }

  return (
    <Step currentSlide={currentSlide} slideIndex={slideIndex}>
      <StepHeader>
        <Figure>
          <StyledPicture
            style={{}}
            breakpoints={{
              mobile: {
                url: image.mobileUrl ?? image.defaultUrl ?? '',
                width: 722,
                height: 361,
              },
              tablet: {
                url: image.tabletUrl ?? image.defaultUrl ?? '',
                width: 991,
                height: 496,
              },
              desktop: {
                url: image.desktopUrl ?? image.defaultUrl ?? '',
                width: 1440,
                height: 720,
              },
            }}
            alt={image.title ?? ''}
            lazy={false}
          />
        </Figure>

        <StepTitle>
          {slideIndex + 1}/{stepCount} {step.stepTitle}
        </StepTitle>
      </StepHeader>

      {step.text && (
        <StepContent>
          <HtmlContent html={step.text} />
        </StepContent>
      )}
    </Step>
  )
}

interface Props {
  block: CraftPageBlocksFragmentCraftWizardSliderEntry
}

const CraftWizardSliderBlock = ({ block }: Props) => {
  const stepCount = block.wizardSteps?.length ?? 0
  const [currentSlideMobileAndTablet, setCurrentSlideMobileAndTablet] =
    useState<number>(0)
  const [currentSlideDesktop, setCurrentSlideDesktop] = useState<number>(0)

  if (!block.wizardSteps || block.wizardSteps.length === 0) {
    return null
  }

  const onSlideChangeMobileAndTablet = (slide: number) => {
    setCurrentSlideMobileAndTablet(slide)
  }

  const onSlideChangeDesktop = (slide: number) => {
    setCurrentSlideDesktop(slide)
  }

  return (
    <CraftBlock>
      <Wizard>
        {(block.blockTitle || block.text) && (
          <StyledContainer>
            <Header>
              {block.blockTitle && (
                <StyledSectionTitle title={block.blockTitle} hasLargeTitle />
              )}

              {block.text && <HtmlContent html={block.text} />}
            </Header>
          </StyledContainer>
        )}

        <StyledComponentSliderMobileAndTablet
          slideGap={20}
          onSlideChange={onSlideChangeMobileAndTablet}
          padding={20}
          slidesToShow={1.3}
        >
          {block.wizardSteps.map((step, index) => (
            <SlideStep
              key={index}
              step={step}
              stepCount={stepCount}
              slideIndex={index}
              currentSlide={currentSlideMobileAndTablet}
            />
          ))}
        </StyledComponentSliderMobileAndTablet>

        <StyledComponentSliderDesktop
          slideGap={30}
          onSlideChange={onSlideChangeDesktop}
          padding={370}
          slidesToShow={1}
          showArrows
        >
          {block.wizardSteps.map((step, index) => (
            <SlideStep
              key={index}
              step={step}
              stepCount={stepCount}
              slideIndex={index}
              currentSlide={currentSlideDesktop}
            />
          ))}
        </StyledComponentSliderDesktop>
      </Wizard>
    </CraftBlock>
  )
}

export default CraftWizardSliderBlock
