import { Picture } from '@emico-react/image'
import { Youtube } from '@emico-react/youtube'
import styled from '@emotion/styled'
import React, { useState } from 'react'

import { minWidth } from '@emico/styles'
import { Loader } from '@emico/ui'

import { CraftInformationItemsBlockFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import ButtonSecondary from './ButtonSecondary'
import HtmlContent from './HtmlContent'

const VideoContainer = styled('div', {
  shouldForwardProp: (prop) => !['isHidden'].includes(prop.toString()),
})<{ isHidden?: boolean }>`
  position: relative;
  margin: ${theme.spacing.lg} 0;
  aspect-ratio: 16 / 9;

  &:last-child {
    margin-bottom: 0;
  }
`

const Figure = styled.figure`
  margin-bottom: ${theme.spacing.lg};

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
`

const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.backgroundLight};
  z-index: 1;
`

const StyledYoutube = styled(Youtube, {
  shouldForwardProp: (prop) => !['isHidden'].includes(prop.toString()),
})<{ isHidden?: boolean }>`
  width: 100%;
  height: 100%;

  @media ${minWidth('md')} {
    visibility: ${({ isHidden }) => isHidden && 'hidden'};
    position: ${({ isHidden }) => isHidden && 'absolute'};
  }
`

interface Props {
  block: CraftInformationItemsBlockFragment
}

const CraftInformationItemBlockContent = ({ block }: Props) => {
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false)

  const image = block.image?.[0]

  return (
    <>
      {block.text && <HtmlContent html={block.text} />}

      {block.itemLink?.url && block.itemLink.customText && (
        <ButtonSecondary
          craftLink={block.itemLink}
          analyticsContext="accordion-information"
          analyticsName={block.itemLink.customText}
          colorTheme="dark"
        >
          {block.itemLink.customText}
        </ButtonSecondary>
      )}

      {block.youtubeId && (
        <>
          <VideoContainer isHidden={!isVideoReady}>
            {!isVideoReady && (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}

            <StyledYoutube
              videoId={block.youtubeId}
              onReady={() => setIsVideoReady(true)}
              isHidden={!isVideoReady}
            />
          </VideoContainer>
        </>
      )}

      {image && (
        <Figure>
          <StyledPicture
            style={{}}
            breakpoints={{
              mobile: {
                url: image.mobileUrl ?? image.defaultUrl ?? '',
                width: 722,
                height: undefined,
              },
              tablet: {
                url: image.tabletUrl ?? image.defaultUrl ?? '',
                width: 991,
                height: undefined,
              },
              desktop: {
                url: image.desktopUrl ?? image.defaultUrl ?? '',
                width: 1440,
                height: undefined,
              },
            }}
            alt={image.title ?? ''}
            lazy
          />
        </Figure>
      )}
    </>
  )
}

export default CraftInformationItemBlockContent
