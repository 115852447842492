import styled from '@emotion/styled'
import React from 'react'

import { minWidth } from '@emico/styles'

import { CraftPageBlocksFragmentCraftAccordionInformationEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import Container from './Container'
import CraftBlock from './CraftBlock'
import CraftInformationItemBlockContent from './CraftInformationItemBlockContent'
import SectionTitle from './SectionTitle'

const StyledContainer = styled(Container)`
  @media ${minWidth('lg')} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`

const Column = styled.div`
  @media ${minWidth('lg')} {
    grid-column: 3 / span 8;
  }
`

const Header = styled.header`
  padding-bottom: ${theme.spacing.md};
  border-bottom: ${theme.borders.default};
`

const StyledAccordion = styled(Accordion)`
  border-bottom: ${theme.borders.default};

  ${AccordionHeader} {
    padding: ${theme.spacing.sm} 0;
  }
`

const ItemLabel = styled.span`
  font-weight: ${theme.fontWeights.bold};
  padding-right: ${theme.spacing.md};
`

const ItemContent = styled.div`
  padding-bottom: ${theme.spacing.lg};
`

interface Props {
  block: CraftPageBlocksFragmentCraftAccordionInformationEntry
}

const CraftAccordionInformationBlock = ({ block }: Props) => {
  if (!block.informationItems || block.informationItems.length === 0) {
    return null
  }

  return (
    <CraftBlock>
      <StyledContainer>
        <Column>
          <Header>
            <SectionTitle title={block.blockTitle} hasLargeTitle />
          </Header>

          {block.informationItems.map((item) => {
            if (!item?.itemTitle || !item.text) {
              return null
            }

            return (
              <StyledAccordion
                key={item.itemTitle}
                label={<ItemLabel>{item.itemTitle}</ItemLabel>}
                labelElement="h3"
              >
                <ItemContent>
                  <CraftInformationItemBlockContent block={item} />
                </ItemContent>
              </StyledAccordion>
            )
          })}
        </Column>
      </StyledContainer>
    </CraftBlock>
  )
}

export default CraftAccordionInformationBlock
