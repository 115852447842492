import styled from '@emotion/styled'
import React, { Fragment } from 'react'

import { minWidth } from '@emico/styles'
import { Container, H2 } from '@emico/ui'

import { CraftPageBlocksFragmentCraftImageContentBanner2Entry } from '../lib/craftFragments.generated'
import theme from '../theme'
import ButtonSecondary from './ButtonSecondary'
import CraftBlock from './CraftBlock'
import CraftImage from './CraftImage'
import CraftLink from './CraftLink'
import HtmlContent from './HtmlContent'
import VideoWithPlaceholder from './VideoWithPlaceholder'

enum ImageAlignmentEnum {
  LEFT = 0,
  RIGHT = 1,
}

enum ImageAlignmentMobileEnum {
  BOTTOM = 0,
  TOP = 1,
}

enum ColorThemeEnum {
  PRIMARY = 'primary',
  LIGHT = 'light',
  BASIC = 'basic',
}

enum FontsizeEnum {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

enum FontWeightEnum {
  NORMAL = 'normal',
  MEDIUM = 'medium',
  BOLD = 'bold',
}

const getBackgroundColor = (colorTheme?: string) => {
  switch (colorTheme) {
    case ColorThemeEnum.PRIMARY:
      return theme.colors.primary
    case ColorThemeEnum.LIGHT:
      return theme.colors.grayLight
    default:
      return undefined
  }
}

const getTextColor = (colorTheme?: string) => {
  switch (colorTheme) {
    case ColorThemeEnum.PRIMARY:
      return theme.colors.textLight
    default:
      return theme.colors.text
  }
}

const getFontSize = (fontSize?: string) => {
  switch (fontSize) {
    case FontsizeEnum.SMALL:
      return theme.fontSizes.lg
    case FontsizeEnum.NORMAL:
      return theme.fontSizes.xl
    default:
      return theme.fontSizes['2xl']
  }
}

const getFontWeight = (fontWeight?: string) => {
  switch (fontWeight) {
    case FontWeightEnum.NORMAL:
      return theme.fontWeights.normal
    case FontWeightEnum.MEDIUM:
      return theme.fontWeights.medium
    default:
      return theme.fontWeights.bold
  }
}

interface StylingProps {
  imageAlignment?: boolean
  imageAlignmentMobile?: boolean
  colorTheme?: string
}

const Grid = styled.article`
  display: grid;
  border-radius: ${theme.borderRadius.base};
  overflow: hidden;

  @media ${minWidth('md')} {
    grid-template-columns: 1fr 1fr;
    justify-items: end;
  }
`

const Figure = styled.figure`
  margin: 0;
`

const StyledCraftImage = styled(CraftImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const Content = styled('div', {
  shouldForwardProp: (prop) =>
    !['imageAlignment', 'imageAlignmentMobile', 'colorTheme'].includes(
      prop.toString(),
    ),
})<StylingProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ colorTheme }) =>
    colorTheme === ColorThemeEnum.BASIC && theme.spacing.lg};
  position: relative;
  background-color: ${({ colorTheme }) => getBackgroundColor(colorTheme)};
  padding: ${theme.spacing.md};
  order: ${({ imageAlignmentMobile }) =>
    imageAlignmentMobile === Boolean(ImageAlignmentMobileEnum.BOTTOM) ? 0 : 1};

  @media ${minWidth('md')} {
    order: ${({ imageAlignment }) =>
      imageAlignment === Boolean(ImageAlignmentEnum.RIGHT) ? 0 : 1};
    padding: ${theme.spacing['2xl']};
    margin-bottom: 0;
  }

  @media ${minWidth('xl')} {
    padding: ${theme.spacing['4xl']};
  }
`

const Header = styled.header`
  margin-bottom: ${theme.spacing.md};
`

const Title = styled(H2, {
  shouldForwardProp: (prop) => !['colorTheme'].includes(prop.toString()),
})<StylingProps>`
  color: ${({ colorTheme }) => getTextColor(colorTheme)};
  text-transform: none;
`

const TitlePart = styled('span', {
  shouldForwardProp: (prop) =>
    !['fontSize', 'fontWeight', 'isUppercase'].includes(prop.toString()),
})<{
  fontSize?: string
  fontWeight?: string
  isUppercase?: boolean
}>`
  display: inline-block;
  margin: 0 ${theme.spacing.xs} ${theme.spacing.xs} 0;
  font-size: ${({ fontSize }) => getFontSize(fontSize)};
  font-weight: ${({ fontWeight }) => getFontWeight(fontWeight)};
  text-transform: ${({ isUppercase }) => isUppercase && 'uppercase'};

  &:last-child {
    margin-bottom: 0;
  }
`

const RichText = styled('div', {
  shouldForwardProp: (prop) => !['colorTheme'].includes(prop.toString()),
})<StylingProps>`
  color: ${({ colorTheme }) => getTextColor(colorTheme)};
  margin-bottom: ${theme.spacing.lg};

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledCraftLink = styled(CraftLink)`
  align-self: flex-start;
  border-bottom: ${theme.borders.base} ${theme.colors.text};
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }
`

interface Props {
  block: CraftPageBlocksFragmentCraftImageContentBanner2Entry
}

const CraftImageContentBannerBlock = ({ block }: Props) => {
  const image = block.image?.[0]
  const hasTitle = block.titleWithPartialStyling2?.length !== 0

  return (
    <CraftBlock id={block.anchorLinkId ?? undefined}>
      <Container>
        <Grid>
          {(hasTitle || block.richText || block.blockLink) && (
            <Content
              imageAlignment={block.imageAlignment ?? undefined}
              imageAlignmentMobile={block.imageAlignmentMobile ?? undefined}
              colorTheme={block.colorTheme ?? undefined}
            >
              {hasTitle && (
                <Header>
                  <Title colorTheme={block.colorTheme ?? undefined}>
                    {block.titleWithPartialStyling2?.map((titlePart, index) => {
                      if (!titlePart?.text) {
                        return null
                      }

                      return (
                        <Fragment key={index}>
                          {titlePart.startOnNewLine && <br />}

                          <TitlePart
                            fontSize={titlePart.fontSize ?? undefined}
                            fontWeight={titlePart.fontWeight ?? undefined}
                            isUppercase={titlePart.uppercase ?? undefined}
                          >
                            {titlePart.text}
                          </TitlePart>
                        </Fragment>
                      )
                    })}
                  </Title>
                </Header>
              )}

              {block.richText && (
                <RichText colorTheme={block.colorTheme ?? undefined}>
                  <HtmlContent html={block.richText} />
                </RichText>
              )}

              {block.blockLink?.url &&
                block.blockLink.customText &&
                (block.colorTheme === ColorThemeEnum.BASIC ? (
                  <StyledCraftLink
                    craftLink={block.blockLink}
                    analyticsContext="imageContentBanner"
                    analyticsName={block.blockLink.customText}
                  >
                    {block.blockLink.customText}
                  </StyledCraftLink>
                ) : (
                  <ButtonSecondary
                    craftLink={block.blockLink}
                    analyticsContext="imageContentBanner"
                    analyticsName={block.blockLink.customText}
                    colorTheme={
                      block.colorTheme === ColorThemeEnum.LIGHT
                        ? 'dark'
                        : 'light'
                    }
                  >
                    {block.blockLink.customText}
                  </ButtonSecondary>
                ))}
            </Content>
          )}

          {block.youtubeId ? (
            <VideoWithPlaceholder
              videoId={block.youtubeId}
              placeholderImage={image}
              hasFullHeight
            />
          ) : (
            image && (
              <Figure>
                <StyledCraftImage
                  image={image}
                  sizes={theme.imageSizes.halfWidth.sizes}
                />
              </Figure>
            )
          )}
        </Grid>
      </Container>
    </CraftBlock>
  )
}

export default CraftImageContentBannerBlock
