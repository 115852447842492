import { ComponentSlider } from '@emico-react/component-slider'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import { Maybe } from '@emico/graphql-schema-types'
import { minWidth, maxWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import { CraftPageBlocksFragmentCraftCompareSpecificationsEntry } from '../lib/craftFragments.generated'
import Unwrap from '../lib/UnWrap'
import {
  ArrowButton,
  IconWrapper,
  PrevButton,
  StyledNextButton,
} from '../overrides/ComponentSlider'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import CraftCompareSpecificationsColumn from './CraftCompareSpecificationsColumn'

const StyledCraftBlock = styled(CraftBlock)`
  padding: ${theme.spacing.xl} 0;
  background: ${theme.colors.backgroundLight};

  @media ${minWidth('md')} {
    padding: ${theme.spacing['2xl']} 0;
  }

  @media ${minWidth('lg')} {
    padding: ${theme.spacing['3xl']} 0;
  }
`

const SpecificationsMobile = styled.div`
  @media ${minWidth('md')} {
    display: none;
  }
`

const SpecificationsTablet = styled.div`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const SpecificationsDesktop = styled.div`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const StyledComponentSlider = styled(ComponentSlider)`
  @media ${minWidth('lg')} {
    margin-top: ${theme.spacing['2xl']};
    padding: 0 ${theme.spacing['3xl']};

    ${() => css`
      ${IconWrapper} {
        background-color: ${theme.colors.background};
      }

      ${ArrowButton} {
        &:hover {
          ${IconWrapper} {
            background-color: ${theme.colors.background};
          }
        }

        &:disabled {
          ${IconWrapper} {
            background-color: ${theme.colors.background};
          }
        }
      }

      ${PrevButton}, ${StyledNextButton} {
        height: 100%;
        top: 100%;
        display: flex;
        align-items: center;
      }

      ${PrevButton} {
        left: 0;
        right: auto;
        justify-content: flex-end;
      }
    `}
  }
`

const Grid = styled('div', {
  shouldForwardProp: (prop) => !['itemCount'].includes(prop.toString()),
})<{ itemCount?: number }>`
  display: grid;
  grid-template-columns: ${({ itemCount }) =>
    `repeat(${itemCount === 1 ? 2 : itemCount}, 1fr)`};
  grid-gap: ${theme.spacing.md};

  @media ${minWidth('md')} {
    grid-gap: ${theme.spacing.lg};
  }

  @media ${minWidth('lg')} {
    grid-gap: ${theme.spacing.xl};
  }
`

type ColumnType = Unwrap<
  Maybe<
    Exclude<
      CraftPageBlocksFragmentCraftCompareSpecificationsEntry['columns'],
      null
    >
  >
>

interface SliderProps
  extends Omit<ComponentProps<typeof ComponentSlider>, 'children'> {
  columns: ColumnType[]
  showLabels?: boolean
}

const Slider = ({ columns, showLabels, ...other }: SliderProps) => (
  <StyledComponentSlider snapAlign="start" slideGap={15} {...other}>
    {columns?.map((column, index) => (
      <CraftCompareSpecificationsColumn
        key={index}
        column={column}
        showLabels={showLabels}
      />
    ))}
  </StyledComponentSlider>
)

interface Props {
  block: CraftPageBlocksFragmentCraftCompareSpecificationsEntry
}

const CraftCompareSpecificationsBlock = ({ block }: Props) => {
  if (!block.columns || block.columns.length === 0) {
    return null
  }

  return (
    <StyledCraftBlock>
      <Container>
        <SpecificationsMobile>
          <Slider
            columns={block.columns}
            showLabels={block.showLabels ?? false}
            slidesToShow={2}
          />
        </SpecificationsMobile>

        <SpecificationsTablet>
          <Slider
            columns={block.columns}
            showLabels={block.showLabels ?? false}
            slidesToShow={2.5}
          />
        </SpecificationsTablet>

        <SpecificationsDesktop>
          {block.columns.length > 4 ? (
            <Slider
              columns={block.columns}
              showLabels={block.showLabels ?? false}
              slidesToShow={4}
              showArrows
            />
          ) : (
            <Grid itemCount={block.columns.length}>
              {block.columns?.map((column, index) => (
                <CraftCompareSpecificationsColumn
                  key={index}
                  column={column}
                  showLabels={block.showLabels}
                />
              ))}
            </Grid>
          )}
        </SpecificationsDesktop>
      </Container>
    </StyledCraftBlock>
  )
}

export default CraftCompareSpecificationsBlock
