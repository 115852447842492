import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import React from 'react'

import { minWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import ArrowRightIcon from '../icons/ArrowRightIcon'
import {
  CraftGenericBlockLinkFragment,
  CraftImageFragment,
  CraftPageBlocksFragmentCraftHighlightBannerLink2Entry,
  CraftProductPageBlocksFragmentCraftHighlightBannerLinkEntry,
} from '../lib/craftFragments.generated'
import replacePlaceholderWithProductName from '../lib/replacePlaceholderWithProductName'
import { ProductQuery } from '../pages/product/useProduct.generated'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import CraftImage from './CraftImage'
import CraftLink from './CraftLink'
import SectionHeader, { StyledSectionTitle, Subtitle } from './SectionHeader'

enum VariantEnum {
  BACKGROUND_IMAGE = 'backgroundImage',
  BACKGROUND_COLOR = 'backgroundColor',
}

const Grid = styled.div`
  display: grid;
  grid-gap: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${minWidth('lg')} {
    grid-gap: ${theme.spacing['2xl']};
  }
`

const StyledHighlightBanner = styled('article', {
  shouldForwardProp: (prop) =>
    !['isFullWidth', 'variant'].includes(prop.toString()),
})<{
  isFullWidth?: boolean
  variant?: string
}>`
  background-color: ${({ variant }) =>
    variant === VariantEnum.BACKGROUND_COLOR && theme.colors.backgroundLight};
  border-radius: ${({ variant }) =>
    variant === VariantEnum.BACKGROUND_COLOR && theme.borderRadius.base};

  @media ${minWidth('md')} {
    grid-column: ${({ isFullWidth }) => isFullWidth && '1 / 3'};
  }
`

const IconWrapper = styled('span', {
  shouldForwardProp: (prop) => !['hasSubtitle'].includes(prop.toString()),
})<{
  hasSubtitle: boolean
}>`
  font-size: ${({ hasSubtitle }) =>
    hasSubtitle ? theme.fontSizes.md : theme.fontSizes.lg};

  @keyframes pulseRight {
    0% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(30px);
    }
    80% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }

  @media ${minWidth('lg')} {
    font-size: ${({ hasSubtitle }) =>
      hasSubtitle ? theme.fontSizes.xl : theme.fontSizes['2xl']};
    animation-name: pulseRight;
    animation-duration: 2s;
    animation-delay: ${theme.transition.durations.extraSlow};
    animation-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: paused;
  }
`

const StyledArrowRightIcon = styled(ArrowRightIcon, {
  shouldForwardProp: (prop) => !['variant'].includes(prop.toString()),
})<{
  variant?: string
}>`
  color: ${({ variant }) =>
    variant === VariantEnum.BACKGROUND_COLOR
      ? theme.colors.text
      : theme.colors.textLight};
  font-size: 16px;
  margin-left: ${theme.spacing.xs};

  @media ${minWidth('md')} {
    margin-left: ${theme.spacing.sm};
  }
`

const StyledCraftLink = styled(CraftLink)`
  text-decoration: none;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: end;
  height: 100%;

  @media ${minWidth('lg')} {
    ${theme.animation.zoomNestedImage(
      1.05,
      theme.transition.durations.extraSlow,
    )};

    &:hover {
      ${IconWrapper} {
        animation-play-state: running;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
`

const Figure = styled('figure', {
  shouldForwardProp: (prop) => !['variant'].includes(prop.toString()),
})<{
  variant?: string
}>`
  grid-row: ${({ variant }) => variant === VariantEnum.BACKGROUND_IMAGE && 1};
  grid-column: 1 / span 12;
  padding: ${({ variant }) =>
    variant === VariantEnum.BACKGROUND_COLOR &&
    `${theme.spacing.lg} ${theme.spacing.lg} 0`};
  margin: 0;
  ${({ variant }) =>
    variant === VariantEnum.BACKGROUND_IMAGE &&
    theme.stylingSnippet.transparentGradientOverlay()};

  @media ${minWidth('lg')} {
    padding: ${({ variant }) =>
      variant === VariantEnum.BACKGROUND_COLOR &&
      `${theme.spacing.xl} ${theme.spacing.xl} 0`};
  }
`

const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
  border-radius: ${theme.borderRadius.base};
`

const StyledCraftImage = styled(CraftImage)`
  width: 100%;
  height: auto;
`

const Content = styled('div', {
  shouldForwardProp: (prop) => !['variant'].includes(prop.toString()),
})<{
  variant?: string
}>`
  grid-row: ${({ variant }) => variant === VariantEnum.BACKGROUND_IMAGE && 1};
  grid-column: 1 / span 12;
  padding: ${theme.spacing.lg};
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media ${minWidth('lg')} {
    padding: ${theme.spacing.xl};
    justify-content: initial;
  }
`

const StyledSectionHeader = styled(SectionHeader, {
  shouldForwardProp: (prop) => !['variant'].includes(prop.toString()),
})<{ variant?: string }>`
  margin-bottom: 0;

  @media ${minWidth('lg')} {
    margin-bottom: 0;
  }

  ${StyledSectionTitle} {
    color: ${({ variant }) =>
      variant === VariantEnum.BACKGROUND_IMAGE
        ? theme.colors.textLight
        : theme.colors.text};
  }

  ${Subtitle} {
    color: ${({ variant }) =>
      variant === VariantEnum.BACKGROUND_IMAGE
        ? theme.colors.textLight
        : theme.colors.text};

    text-transform: none;
    font-size: ${theme.fontSizes.md};

    @media ${minWidth('lg')} {
      font-size: ${theme.fontSizes.xl};
    }
  }
`

interface Image extends CraftImageFragment {
  desktopUrl: string | null
  desktopCustomUrl?: string | null
  tabletUrl: string | null
  tabletCustomUrl?: string | null
  mobileUrl: string | null
}

interface Item extends Omit<CraftGenericBlockLinkFragment, 'image'> {
  image: Array<Image | null>
}

interface HighlightBannerProps {
  item: Item
  showIcon?: boolean
  isFullWidth?: boolean
  variant?: string
  productData?: ProductQuery
}

export const HighlightBanner = ({
  item,
  isFullWidth,
  showIcon,
  variant,
  productData,
}: HighlightBannerProps) => {
  const image = item.image?.[0]

  if (!item.linkField || !image || !item.title) {
    return null
  }

  const title = productData?.productByUid?.name
    ? replacePlaceholderWithProductName(
        item.title,
        productData?.productByUid?.name,
      )
    : item.title

  return (
    <StyledHighlightBanner isFullWidth={isFullWidth} variant={variant}>
      <StyledCraftLink
        craftLink={item.linkField}
        analyticsContext="highlightBanner"
        analyticsName={title}
      >
        <Figure variant={variant}>
          {variant === VariantEnum.BACKGROUND_COLOR ? (
            <StyledCraftImage
              image={image}
              sizes={theme.imageSizes.halfWidth.sizes}
            />
          ) : (
            <StyledPicture
              style={{}}
              breakpoints={{
                mobile: {
                  url: image.mobileUrl ?? image.defaultUrl ?? '',
                  width: theme.imageSizes.highlightBanner.sizes.xs,
                  height: 260,
                },
                tablet: {
                  url:
                    (isFullWidth ? image.tabletUrl : image.tabletCustomUrl) ??
                    image.defaultUrl ??
                    '',
                  width: theme.imageSizes.highlightBanner.sizes.md,
                  height: isFullWidth ? 357 : 660,
                },
                desktop: {
                  url:
                    (isFullWidth ? image.desktopUrl : image.desktopCustomUrl) ??
                    image.defaultUrl ??
                    '',
                  width: theme.imageSizes.highlightBanner.sizes.lg,
                  height: isFullWidth ? 518 : 960,
                },
              }}
              alt={image.title ?? ''}
              lazy
            />
          )}
        </Figure>

        <Content variant={variant}>
          <StyledSectionHeader
            title={title}
            subtitle={item.subtitle}
            variant={variant}
            hasLargeTitle
          />

          {showIcon && (
            <IconWrapper hasSubtitle={Boolean(item.subtitle)}>
              <StyledArrowRightIcon variant={variant} />
            </IconWrapper>
          )}
        </Content>
      </StyledCraftLink>
    </StyledHighlightBanner>
  )
}

interface Props {
  block:
    | CraftPageBlocksFragmentCraftHighlightBannerLink2Entry
    | CraftProductPageBlocksFragmentCraftHighlightBannerLinkEntry
  productData?: ProductQuery
}

const CraftHighlightBannerLinkBlock = ({
  block,
  productData,
  ...other
}: Props) => {
  const itemCount = block.highlightedItems?.length ?? 0
  const firstItem = block.highlightedItems?.[0]

  if (itemCount === 0) {
    return null
  }

  return (
    <CraftBlock {...other}>
      <Container>
        {itemCount === 1 &&
        firstItem?.__typename === 'CraftGenericBlockLinkEntry' ? (
          <HighlightBanner
            item={firstItem}
            isFullWidth
            showIcon={block.showIcon ?? undefined}
            variant={block.variant ?? undefined}
            productData={productData}
          />
        ) : (
          <Grid>
            {block.highlightedItems?.map((item, index) => {
              if (item?.__typename !== 'CraftGenericBlockLinkEntry') {
                return null
              }

              return (
                <HighlightBanner
                  key={index}
                  item={item}
                  isFullWidth={
                    (index === 0 && 'fullWidth' in block && block.fullWidth) ??
                    undefined
                  }
                  showIcon={block.showIcon ?? undefined}
                  variant={block.variant ?? undefined}
                  productData={productData}
                />
              )
            })}
          </Grid>
        )}
      </Container>
    </CraftBlock>
  )
}

export default CraftHighlightBannerLinkBlock
